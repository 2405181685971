// export const ENCRYPT_JSON = 'application/encrypt-json';

export const APPKEY = 'web_association_manage_1.0.0'

// export const AUTO_CANCEL_TOKEN_MESSAGE = '请求重复，自动取消';

// export const FIXED_TELEPHONE = '400-9900-115';

/**
 * 存储cookie或者storage别名。
 */

// export const COOKIE_ROLEAUTH = 'Eg9X82JeeOc5WAnbtJ'; // 角色权限

// export const COOKIE_EXPIRETIME = 'Ex9yCZUph9qcagqZN5'; // token过期时间
const url = {
	// 律协
	'henan-zz': 'henan-zz',
	hn: 'henan-svc',
}
/**
 * 请求地址
 */
export const ASSOCIATION_PREFIX_URL = '/association' // 律协请求地址前缀
// export const COMMON_API_HENAN_API_PREFIX = '/henan-svc' // 河南省
let project = localStorage.getItem('projectType')
console.log('🚀 ~ project', project)
export const COMMON_API_HENAN_CHILD = (project === 'hn' ? 'henan-svc' : project) || 'support' // 河南省

// export const COMMON_API_HENAN_API_PREFIX = project&&url?.[project]  || 'henan-zz' // 文件请求地址前缀
export const COMMON_API_HENAN_API_PREFIX = project === 'hn' ? 'henan-svc' : project
export const COMMON_API_PREFIX = '/support' // 文件请求地址前缀
