<template>
	<router-view v-slot="{ Component }">
		<component :is="Component" />
	</router-view>
</template>
<script setup lang="ts">
import { isInWujie, WujieBusEnum } from '@common/utils'
import { bus } from 'wujie'
import { useLoginInfo } from '@/hooks'
import { useStore } from './store'
import { cache } from './utils'

const { setLoginInfo } = useLoginInfo()
const setupWujie = () => {
	const { userInfo, tokenInfo } = window.$wujie.props?.state || {}
	const project = window.$wujie.props?.project || ''
	const examTabType = window.$wujie.props?.examTabType || ''
	if (project) {
		cache.set('projectType', project)
	}
	// cache.set('examTabType', examTabType)
	if (examTabType) {
		cache.set('examTabType', examTabType)
	}
	if (userInfo && tokenInfo) {
		setLoginInfo({ userInfo, tokenInfo })
	}
	bus.$on(WujieBusEnum.SYNC_STATE, (res) => {
		// TODO: 增加重定向处理
		console.log('🚀 ~ e接收到' + WujieBusEnum.SYNC_STATE, res)
		setLoginInfo(res)
	})
}
console.log(isInWujie(), '是否无界')

isInWujie() && setupWujie()
</script>
