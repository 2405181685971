<template>
  <div class="zf-avatar">
    <!--  由头像的情况  -->
    <template v-if="src">
      <a-tooltip v-if="isShowTip" popper-class="avatar-name-tooltip" placement="top">
        <template #title>{{ avatarName }}</template>
        <a-avatar :size="size" :src="src"></a-avatar>
      </a-tooltip>
      <a-avatar v-else :size="size" :src="src"></a-avatar>
    </template>
    <!--  没有头像的情况  -->
    <template v-else>
      <a-tooltip v-if="isShowTip" popper-class="avatar-name-tooltip" placement="top">
        <template #title>{{ avatarName }}</template>
        <div class="avatar-box">
          <!--   名字填充到box   -->
          <div
            class="name-img"
            :style="{
              background: bgColor,
              width: size + 'px',
              height: size + 'px',
              borderRadius: size / 2 + 'px'
            }"
          >
            <span class="name-font" :style="{ transform: `scale(${scaleName})`, fontSize: 12 + 'px' }">
              <!--  取名字后面的两个字     -->
              {{ avatarName.slice(-2) }}
            </span>
          </div>
        </div>
      </a-tooltip>
      <div v-else class="avatar-box">
        <!--   名字填充到box   -->
        <div
          class="name-img"
          :style="{
            background: bgColor,
            width: size + 'px',
            height: size + 'px',
            borderRadius: size / 2 + 'px'
          }"
        >
          <span class="name-font" :style="{ transform: `scale(${scaleName})`, fontSize: 12 + 'px' }">
            <!--  取名字后面的两个字     -->
            {{ avatarName.slice(-2) }}
          </span>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent } from 'vue';
export default defineComponent({
  name: 'ZfAvatar',
  props: {
    src: { type: String, default: '' }, // 头像地址
    avatarName: { type: String, default: '' }, // 名字
    size: { type: Number, default: 24 }, // 头像大小
    scaleName: { type: Number, default: 1 }, // 没有头像的情况可以缩放字体大小
    isShowTip: { type: Boolean, default: false } // 是否展示el-tooltip
  },
  setup() {
    const bgColor = ref('#625FEC');

    return { bgColor };
  }
});
</script>

<style lang="less" scoped>
.zf-avatar {
  .flexDisplay(flex-start, center);

  position: relative;
  box-sizing: border-box;
  //padding: 0 16px 0 0;
  border: 1px dashed rgba(255, 255, 255, 0);

  &__name {
    margin: 0 8px;
    font-size: 14px;
    white-space: nowrap;
  }

  .avatar-box {
    .flexDisplay(flex-start, center);
    //.wh(100%, 100%);

    .name-img {
      .flexDisplay(center, center);

      color: #fff;
      background: rgb(122, 122, 204);

      .name-font {
        display: inline-block !important;
        white-space: nowrap;
      }
    }
  }
}
</style>
<style lang="less">
.avatar-name-tooltip {
  max-width: 400px;
}
</style>
