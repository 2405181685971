<template>
  <!-- <SuspenseWithError> -->
  <router-view v-slot="{ Component }">
    <Transition name="fade-slide" mode="out-in" appear>
      <Component :is="Component" />
    </Transition>
  </router-view>
  <!-- </SuspenseWithError> -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SuspenseWithError from '@/components/SuspenseWithError.vue';

export default defineComponent({
  name: 'RouterTransition',
  components: { SuspenseWithError },
  props: {
    notNeedKey: {
      type: Boolean,
      default: false,
    },
    animate: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {};
  },
});
</script>
