import { TargetContext } from '@common/types'
import { extarctKeyValuePairsFromArrObj, isObject, isString } from '@common/utils'

/**
 * 不足length位数时自动补0
 */
export function autoFill(val: string | number, length = 2) {
  val = String(val)
  while (val.length < length) {
    val = 0 + val
  }
  return val
}

/**
 * 把 dataURL 转成 blob
 **/
export function dataURLToBlob(dataurl: string): Blob {
  let arr = dataurl.split(',')
  let mime = arr[0].match(/:(.*?);/)?.[1]
  let bstr = atob(arr[1])
  let n = bstr.length
  let u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

/**
 * @param url 弹出窗口的rul
 */
export const openWindowCenter = (url: string, options: { target?: string; width: number; height: number }) => {
  const { width, height } = options
  const top = (window.screen.availHeight - height) / 2 // 获得窗口的垂直位置;
  const left = (window.screen.availWidth - width) / 2 // 获得窗口的水平位置;
  window.open(url, '_blank', 'height=' + height + ', width=' + width + ', top=' + top + ', left=' + left)
}

export function openWindow(
  url: string,
  opt?: { target?: TargetContext | string; noopener?: boolean; noreferrer?: boolean },
) {
  const { target = '__blank', noopener = true, noreferrer = true } = opt || {}
  const feature: string[] = []
  noopener && feature.push('noopener=yes')
  noreferrer && feature.push('noreferrer=yes')
  window.open(url, target, feature.join(','))
}

export const sleep = (delay: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(resolve, delay)
  })
}

// 格式化文件大小单位
export function formatFileSize(fileSize: number, idx = 0): string {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB']
  if (fileSize < 1024 || idx === units.length - 1) {
    return fileSize.toFixed(2) + units[idx]
  }
  return formatFileSize(fileSize / 1024, ++idx)
}

/**
 * 从查询字符串中解析出query
 */
export const parseQueryString = <T extends Record<string, string>>(url: string) => {
  const rawParams: string | undefined = url.split('?')[1]
  return (rawParams?.split('&').reduce<Record<string, string>>((accumulator, item) => {
    const paramsArr = item.split('=')
    const key = paramsArr[0]
    if (key) {
      accumulator[key] = paramsArr[1] || ''
    }
    return accumulator
  }, {}) || {}) as Partial<T>
}
/**
 * 通过对象生成查询字符串
 */
export const genQueryString = (obj: Record<string, any>, options?: { encode?: boolean }) => {
  const { encode } = options || {}
  const arr = Object.keys(obj)
  if (arr.length) {
    const result = arr.reduce((acc, item, index) => {
      if (index === arr.length - 1) {
        acc += item + '=' + obj[item]
      } else {
        acc += item + '=' + obj[item] + '&'
      }
      return acc
    }, '?')
    return encode ? result[0] + encodeURIComponent(result.slice(1)) : result
  }
  return ''
}

export const genUploadAccept = (suffixes: string[]) =>
  suffixes.reduce((acc, item, index) => {
    acc += '.' + item
    if (index !== suffixes.length - 1) {
      acc += ','
    }
    return acc
  }, '')
/**
 * 字符串化对象数组
 * 	
  @example stringifyArr([{ username: '马哥', password: '哈哈' }], {
    fieldConfigs: [
      { field: 'password', prefix: '密码: ' },
      { field: 'username', prefix: '用户名: ' },
    ],
  }),
 */
export const stringifyArr = <T extends Record<string, any> | string>(
  arr?: T[],
  options?: {
    /**
     * 分隔符配置
     */
    separator?: {
      /**
       * 指定数组中每一项之间的分隔符
       */
      item?: string
      /**
       * 指定每一项里每个属性之间的分隔符
       */
      property?: string
    }
    /**
     * 字段配置
     */
    fieldConfigs?:
    | {
      field: keyof T & string
      /**
       * 指定字段值的前缀
       */
      prefix?: string
      /**
       * 指定字段值的后缀
       */
      suffix?: string
    }[]
    | {
      /**
       * 指定字段值的前缀
       */
      prefix?: string
      /**
       * 指定字段值的后缀
       */
      suffix?: string
    }
  },
) => {
  const { fieldConfigs = [], separator } = options || {}
  const propertySeparator = separator?.property || ' '
  const itemSeparator = separator?.item || '\n'

  const preffixConfig = Array.isArray(fieldConfigs)
    ? (extarctKeyValuePairsFromArrObj(fieldConfigs, 'field', 'prefix') as Record<string, string | undefined>)
    : fieldConfigs.prefix || ''
  const suffixConfig = Array.isArray(fieldConfigs)
    ? (extarctKeyValuePairsFromArrObj(fieldConfigs, 'field', 'suffix') as Record<string, string | undefined>)
    : fieldConfigs.suffix || ''

  // 处理item 加上前后缀
  const processItem = (text: string, field?: string) =>
    (isString(preffixConfig) ? preffixConfig : field ? preffixConfig[field] || '' : '') +
    text +
    (isString(suffixConfig) ? suffixConfig : field ? suffixConfig[field] || '' : '')

  return arr?.reduce((result, item, index) => {
    if (isObject(item)) {
      const processedItem = Object.keys(item).reduce((acc, key) => {
        acc[key as keyof T] = processItem(item[key], key) as unknown as T[keyof T]
        return acc
      }, {} as T)
      result += Object.values(processedItem).join(propertySeparator)
    } else {
      result += processItem(item)
    }

    if (index !== result.length - 1) {
      result += itemSeparator
    }
    return result
  }, '')
}

// TODO: 需要改名 Falsy比null, undefined更广
export const excludeFalsyProperty = (value: Record<string, any>) => {
  for (const key in value) {
    if (Object.hasOwn(value, key)) {
      const element = value[key]
      if ([null, undefined].includes(element)) {
        delete value[key]
      }
    }
  }
  return value
}


/**
 *
 * content : 需要复制的内容
 * message : 复制完后的提示，不传则默认提示"复制成功"
 */
export const copyToClip = (content: string, masge?: string) => {
  const input = document.createElement('textarea');
  document.body.appendChild(input);
  input.innerHTML = content;
  input.setAttribute('code', '1');
  input.select();
  document.execCommand("Copy");
  let list = document.getElementsByTagName('textarea');
  let inputList = Array.prototype.slice.call(list);
  inputList.forEach((item) => {
    if (item.getAttribute('code')) document.body.removeChild(item);
  });
}
