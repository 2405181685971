import { AddPrefix, AddSuffix, StrConnector } from '@common/types'

/**
 * 给字符串添加前缀并转成小驼峰
 */
export const addPrefix = <T extends string, Prefix extends string>(str: T, prefix: Prefix) => {
	const [first = '', ...rest] = str
	return `${prefix}${prefix ? first.toLocaleUpperCase() || '' : first}${rest.join(
		'',
	)}` as AddPrefix<T, Prefix>
}
/**
 * 给字符串添加后缀并转成小驼峰
 */
export const addSuffix = <T extends string, Suffix extends string>(str: T, suffix: Suffix) => {
	const [first = '', ...rest] = suffix
	return `${str}${str ? first.toLocaleUpperCase() || '' : first}${rest.join('')}` as AddSuffix<
		T,
		Suffix
	>
}

/**
 * 连接多个字符串并转成小驼峰
 * TODO: 预留第二个参数作为选项, 增加大驼峰等其他连接方式
 */
export const strConnector = <T extends readonly string[]>(strs: T) =>
	strs.reduce((acc, item) => addSuffix(acc, item)) as StrConnector<T>
