import {
  ExtarctKeysFromArrObj,
  GroupBy,
  ExtarctKeyValuePairsFromArrObj,
  ValidKeys,
} from '@common/types'

const toRawType = (val: unknown) => Object.prototype.toString.call(val).slice(8, -1)

const isPropertyKey = (val: unknown): val is PropertyKey =>
  ['String', 'Number', 'Symbol'].includes(toRawType(val))

// Use lodash.pick instead
const pick = <T extends object>(target: T, keys: (keyof T)[]) => {
  return keys.reduce((dict, key) => ({ ...dict, [key]: target[key] }), {})
}

export function defineDictionary<T extends readonly any[]>(defs: T) {
  /**
   * 获取由指定的键组成的数组
   */
  const keys = <K extends keyof T[number]>(key: K) => {
    return defs.map((def) => def[key]) as ExtarctKeysFromArrObj<T, K>
  }
  /**
   * 获取由指定的键和值组成的键值对
   */
  const keyValuePairs = <K extends ValidKeys<T[number]>, V extends keyof Omit<T[number], K>>(
    key: K,
    value: V,
  ): ExtarctKeyValuePairsFromArrObj<T, K, V> => {
    return defs.reduce((map, def) => {
      const _key = def[key]
      if (!isPropertyKey(_key)) return map
      const _val = def[value]
      return { ...map, [_key]: _val }
    }, {})
  }
  /**
   * 按指定的键分组
   * @param key 指定的键
   * @param values 分组后的值应该包含的键名, 不传则返回全部
   */
  const groupBy = <K extends ValidKeys<T[number]>, V extends keyof T[number]>(
    key: K,
    values?: V[],
  ): GroupBy<T, K, V> => {
    return defs.reduce((map, def) => {
      const _key = def[key]
      if (!isPropertyKey(_key)) return map
      return { ...map, [_key]: values ? pick(def, values) : def }
    }, {})
  }
  /**
   * 用数据源执行Array.map
   */
  const map = <U>(callbackfn: (value: T[number], index: number, array: T) => U, thisArg?: any) => {
    return defs.map<U>((value, index, array) => callbackfn(value, index, array as T), thisArg)
  }
  return {
    keys,
    keyValuePairs,
    groupBy,
    map,
  }
}
// const enum MusicType {
// 	POP = 'pop',
// 	RAP = 'rap',
// 	ROCK = 'rock',
// }

// const MusicTypeDefs = [
// 	{
// 		key: MusicType.POP,
// 		value: 1,
// 		name: '流行音乐',
// 		icon: 'pop.svg',
// 		color: 'red',
// 		fn: () => {
// 			console.log(MusicType.POP)
// 		},
// 	},
// 	{
// 		key: MusicType.RAP,
// 		value: 2,
// 		name: '说唱音乐',
// 		icon: 'rap.svg',
// 		color: 'green',
// 		fn: () => {
// 			console.log(MusicType.RAP)
// 		},
// 	},
// 	{
// 		key: MusicType.ROCK,
// 		value: 3,
// 		name: '摇滚音乐',
// 		icon: 'rock.svg',
// 		color: 'pink',
// 		fn: () => {
// 			console.log(MusicType.ROCK)
// 		},
// 	},
// ] as const

// const musicMap = defineDictionary(MusicTypeDefs)
// // keys
// const icons = musicMap.keys('icon').map((item) => item)
// const values = musicMap.keys('value')
// const names = musicMap.keys('name')
// console.log(icons) // ['pop.svg', 'rap.svg', 'rock.svg']
// console.log(values) // [1, 2, 3]
// console.log(names) // ['流行音乐', '说唱音乐', '摇滚音乐']

// // key-value pairs
// const nameIcon = musicMap.keyValuePairs('name', 'icon')
// const keyStyle = musicMap.keyValuePairs('key', 'icon')
// console.log('🚀 ~ keyStyle:', keyStyle)
// // {
// //   流行音乐: "pop.svg";
// //   说唱音乐: "rap.svg";
// //   摇滚音乐: "rock.svg";
// // }
// console.log('🚀 ~ nameIcon:', nameIcon)
// // {
// //   pop: "pop.svg";
// //   rap: "rap.svg";
// //   rock: "rock.svg";
// // }

// // partial property
// const nameIconGroups = musicMap.groupBy('name', ['fn', 'icon'])
// console.log('🚀 ~ nameIconGroups:', nameIconGroups)
// // {
// //   '流行音乐': { fn: [Function: fn], icon: 'pop.svg' },
// //   '说唱音乐': { fn: [Function: fn], icon: 'rap.svg' },
// //   '摇滚音乐': { fn: [Function: fn], icon: 'rock.svg' }
// // }
// const icon = nameIconGroups.摇滚音乐.icon // "rock.svg"

// // full property
// const nameGroups = musicMap.groupBy('name')

// console.log('🚀 ~ nameGroups:', nameGroups)
// // {
// //   '流行音乐': {
// //     key: 'pop',
// //     value: 1,
// //     name: '流行音乐',
// //     icon: 'pop.svg',
// //     color: 'red',
// //     fn: [Function: fn]
// //   },
// //   '说唱音乐': {
// //     key: 'rap',
// //     value: 2,
// //     name: '说唱音乐',
// //     icon: 'rap.svg',
// //     color: 'green',
// //     fn: [Function: fn]
// //   },
// //   '摇滚音乐': {
// //     key: 'rock',
// //     value: 3,
// //     name: '摇滚音乐',
// //     icon: 'rock.svg',
// //     color: 'pink',
// //     fn: [Function: fn]
// //   }
// // }

// // Array.map
// const SelectOptions = musicMap.map((item) => ({ label: item.name, value: item.value }))
